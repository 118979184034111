import React from "react";
import styled from 'styled-components';


export const TesteFont = styled.h1`
    color: black;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 18px;
    align-self: center;
    

`;
