import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoMenuOutline } from "react-icons/io5";
import { MdMenu } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaHeartCirclePlus } from "react-icons/fa6";
import { PiHandsPrayingFill } from "react-icons/pi";
import { PiHandHeartBold } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import { BiSolidMessageAltAdd } from "react-icons/bi";

import gota1 from '../../assets/gota1.png'


import Moment from '../../Moment';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 

import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import { TesteFont } from './Styles';
import topo from '../../assets/topo_new.png';
import topo_desktop from '../../assets/topo_desktop_new.png';
import './Doacoes.css';

function App() {

  // url teste local
  // const baseurl = `https://doevidamacae.appsesites.com.br/ApiDoador.php`;

  //url em produção
  const baseurl = `ApiDoador.php`;

  let date = Moment()
    .utcOffset('-03:00')
    .format('YYYY-MM-DD');
  
console.log(date);


const [valorSelecionado, setValorSelecionado] = useState('');

const handleChange = (event) => {
  setValorSelecionado(event.target.value);
  console.log(event.target.value);
};


  const { innerWidth: width, innerHeight: height } = window;

    const [selectedImage, setSelectedImage] = useState(null);

    const [telefone, setTelefone] = useState();

    const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  console.log(width);

  function executa() {

    try{

    const config = {
      method: 'post',
      //endereço local
      // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=getdoadores`,
      // url: `ApiDoador.php?action=getdoadores`,
      url: baseurl,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout:10000,
      data: {
        'id':'',
        'action': 'getdoador'
      }
    };

    const req = axios(config).then((res) => {
      var temp = [];

      console.log(res);
      for (let i = 0; i < res.data.length; ++i)
        temp.push(res.data[i]);
     
      setFilteredData(temp);
      setMasterData(temp);
      

    }).catch((err) => {
      executa();
    });
  }catch (error) {
    if (axios.isCancel(error)) {
      console.log('A solicitação foi cancelada devido a timeout');
      executa();
      // Aqui você pode fazer algo, como tentar novamente a consulta
      // ou exibir uma mensagem de erro para o usuário
    } else {
      // Lidar com outros erros de rede ou do servidor
      console.error('Erro:', error.message);
      throw error;
    }

  }
}


function handleSetarEnviado(iddoacao) {  
   
      const config = {
          method: 'post',
          // url local
          // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=doador_enviado`,
          // url: `ApiDoador.php?action=insert_doador`,
          url: baseurl,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout:5000,
          data: {
            'id': iddoacao,                              
            'action': 'doador_enviado'
          }
        };
    
        const req = axios(config).then((res) => {                  
        
         });        
  
}

const handleExcluir = (itemExcluir) => {

  
  const config = {
    method: 'post',
    url: baseurl,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: {
      'id': itemExcluir,        
      'action': 'delete_doador'
    }
  };


  const req = axios(config).then((res) => { 
    toast.success('Doador Excluído com Sucesso!', {              
      onClose: () =>  window.location.href = '/doacoes'
    })
   }).catch((err) => {
    // handleConfirmaExcluir();
  });
  

}

const searchFilterTipo = (tiposanguineo) => {
    
  setValorSelecionado(tiposanguineo);
  

  if (tiposanguineo) {

    const newData = masterData.filter(
      function (item) {
          
        if (item.tiposanguineo) {
            // console.log(item.nome.toUpperCase());
            // console.log(text.toUpperCase());

            
            
          const itemData = item.tiposanguineo.toUpperCase();
          const textData = tiposanguineo.toUpperCase();

          const regex = new RegExp(`\\b${itemData}\\b`, 'i');
          console.log('sdadasdasdasda' + regex.test(textData));

          return regex.test(textData) && itemData.indexOf(textData) > -1;
        }
    });
    setFilteredData(newData);
    setValorSelecionado(tiposanguineo);
  } else {
    // setFilteredData(masterData);
    // setSearch(text);
  }
};

  const searchFilter = (text, tiposanguineo) => {
    
    setValorSelecionado(tiposanguineo);
    if (text) {
      setValorSelecionado('');
      const newData = masterData.filter(
        function (item) {
            console.log('teste');
            console.log(item);
          if (item.nome) {
              // console.log(item.nome.toUpperCase());
              // console.log(text.toUpperCase());
              
            const itemData = item.nome.toUpperCase();
            const textData = text.toUpperCase();
            console.log(itemData.indexOf(textData) > -1);
            return itemData.indexOf(textData) > -1;
          }
      });
      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(masterData);
      setSearch(text);
    }

    if (tiposanguineo) {

      setSearch('');

      
      
      const newData = masterData.filter(
        function (item) {
            
          if (item.tiposanguineo) {
              // console.log(item.nome.toUpperCase());
              // console.log(text.toUpperCase());

              
              
            const itemData = item.tiposanguineo.toUpperCase();
            const textData = tiposanguineo.toUpperCase();

            const regex = new RegExp(`${itemData}`, 'i');
            console.log('sdadasdasdasda' + regex.test(textData));

            return regex.test(textData) && itemData.indexOf(textData) > -1;
          }
      });
      setFilteredData(newData);
      setValorSelecionado(tiposanguineo);
    } else {
      // setFilteredData(masterData);
      // setSearch(text);
    }
  };

  useEffect(() => {

    executa();
  }, []);


    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Shared Image',
              text: 'Check out this image!',
              url: 'https://files.tecnoblog.net/wp-content/uploads/2022/04/google_capa-5.jpg'
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Web Share API não suportada no seu navegador.');
        }
      };

    const array_doadores = [
        {
            nome: 'José de Souza',
            telefone: '5522999384187'
        },
        {
            nome: 'Marcos Andtônio Carvalho'
        },
        {
            nome: 'Maria de Souza'
        },
        {
            nome: 'Etelvina Maria da Silva'
        },
        {
            nome: 'Carlos Eduardo Gonçalves'
        },
        {
            nome: 'Lucas Valença'
        },
        {
            nome: 'Felipe Silva'
        },
        {
            nome: 'Rafaela Moreira Vasques'
        }
    ]

    const [result, setResult] = useState('');

  const numbers = [1, 2, 3, 4, 5];
const ListItems = () => {numbers.map((number) =>
  {return <li key={number.toString()}>
    {number}
  </li>}
)};

const shareData = {
    title: "MDN",
    text: "Learn web development on MDN!",
    // url: "https://tibmacae.org.br",
    url: "https://wa.me/5522999384187/?text=teste" + "%20 teste"
    // url: "https://whatsapp://send?text=teste" + "%20 teste",
  };

  const isChrome = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    return /chrome/.test(userAgent) && !/edge|edg|opr|brave/.test(userAgent);
  };

  const shareNovo = async() => {
    if (navigator.share) {
        try {
          await navigator.share({
            title: 'Shared Image',
            text: 'Check out this image!',
            url: '../src/assets/logo192.png'
          });
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Web Share API não suportada no seu navegador.');
      }
  }

  function enviar_mensagem (telefone, tipo) {

   
  if(telefone !== undefined){     
      try {          
          //Configuracao das marcacoes do texto
          //%20 - espaco
          //%0A - pula linha

          const imageUrl = 'https://doadordesangue.appsesites.com.br/imagem.html'; // URL da imagem hospedada
    // const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent('Confira esta imagem incrível: ' + imageUrl)}`;
  
          var whatsappUrl = "https://wa.me/+5522999384187/?text=teste" + "%20 teste";
          // var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0ASua%20doação%20salva%20vidas%20linha%0ATerceira%20linha" + encodeURIComponent("https://tnics.com.br/img/Logo%203.png");
          if(tipo == 'doe'){
            var testeurl = "https://wa.me/"+telefone+"/?text=*Doe%20novamente*!!!,%0A%0ASua%20doação%20salva%20vidas.";
          }else {
            var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0A%0ASua%20doação%20salva%20vidas.";
            // var testeurl = `https://wa.me/`+telefone+`/?text=${encodeURIComponent('Confira esta imagem incrível: ' + imageUrl)}`;
          }
  
          // shareNovo();
          // Abrir o link
          window.open(testeurl, '_blank');
          setResult("MDN shared successfully");
        } catch (err) {
          setResult(`Error: ${err}`);
        }
      }
    // } else {
  
    //   toast.error('Resultado ainda não liberado!', {              
    //     // onClose: () =>  window.location.href = '/'
    //   })
  
    // }
  }

function share (telefone, iddoacao, dias) {

  if(dias >= 3){

    handleSetarEnviado(iddoacao);
// console.log('teste');
    //   if (!isChrome()) {
    //     alert('Este recurso só está disponível no Google Chrome.');
    //     return;
    //   }
// console.log(telefone);
if(telefone !== undefined){
    console.log(telefone);
    try {
        // await navigator.share(shareData);

        //Configuracao das marcacoes do texto
        //%20 - espaco
        //%0A - pula linha

        var whatsappUrl = "https://wa.me/+5522999384187/?text=teste" + "%20 teste";
        // var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0ASua%20doação%20salva%20vidas%20linha%0ATerceira%20linha" + encodeURIComponent("https://tnics.com.br/img/Logo%203.png");
        var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0A%0ASua%20doação%20salva%20vidas.";

        // shareNovo();
        // Abrir o link
        window.open(testeurl, '_blank');
        setResult("MDN shared successfully");
      } catch (err) {
        setResult(`Error: ${err}`);
      }
    }
  } else {

    toast.error('Resultado ainda não liberado!', {              
      // onClose: () =>  window.location.href = '/'
    })

  }
}

const ShareButton = () => {
    const imageUrl = 'https://doadordesangue.appsesites.com.br/imagem.html'; // URL da imagem hospedada
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent('Confira esta imagem incrível: ' + imageUrl)}`;
  
    return (
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        Compartilhar no WhatsApp
      </a>
    );
  };

  const ShareButton2 = () => {
    const handleShare = async() => {
      if (navigator.share) {
        navigator.share({
          title: 'Confira esta imagem!',
          text: 'Veja esta imagem incrível que encontrei!',
          files: [
            new File(
              [await fetch('https://tnics.com.br/img/cameras.jpg').then(r => r.blob())],
              'cameras.jpg',
              { type: 'image/jpg' }
            )
          ],
        })
        .then(() => console.log('Compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar:', error));
      } else {
        alert('Compartilhamento não suportado neste navegador.');
      }
    };
  
    return (
      <button onClick={handleShare}>
        Compartilhar no WhatsApp
      </button>
    );
  };

  function ButtonAdd ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();
    

    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{width:'10vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button onClick={() => handleDoacao(dados)} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont></TesteFont><BiSolidMessageAltAdd  size={30} style={{color:'02843d'}} /></button>
        
        </div>
        :
        <div style={{width:'15vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        
        <button onClick={() => handleDoacao(dados)}  style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont></TesteFont><BiSolidMessageAltAdd  size={30} style={{color:'02843d'}} /></button>
        </div>
    )
    


  }

  function ButtonAgradece ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();

    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{width:'10vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button onClick={() => enviar_mensagem('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), 'agradece')} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont></TesteFont><PiHandsPrayingFill size={30} style={{color:'02843d'}} /></button>
        
        </div>
        :
        <div style={{width:'15vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        
        <button onClick={() => enviar_mensagem('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), 'agradece')}  style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont></TesteFont><PiHandsPrayingFill size={30} style={{color:'02843d'}} /></button>
        </div>
    )
    


  }

  function ButtonDelete({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();


    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{width:'10vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><MdDelete  size={30} style={{color:'#ce023f'}} /></button>
        
        </div>
        :
        <div style={{width:'15vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        
        <button onClick={() => handleExcluir(dados.id)} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><MdDelete  size={30} style={{color:'#ce023f'}} /> </button>
        </div>
    )
    


  }

  function ButtonDoe ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();
    
    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{width:'10vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button onClick={() => enviar_mensagem('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), 'doe')} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><PiHandHeartBold size={30} style={{color:'blue'}} /></button>
        
        </div>
        :
        <div style={{width:'15vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        
        <button onClick={() => enviar_mensagem('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), 'doe')} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><PiHandHeartBold size={30} style={{color:'blue'}} /> </button>
        </div>
    )
    


  }

  function ButtonEdit ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();

    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{width:'10vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><Link to="/cadastrar" state={{ doador: dados }} ><FaEdit size={30} style={{color:'black'}} /></Link></button>
        
        </div>
        :
        <div style={{width:'15vw', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        
        <button style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}></TesteFont><Link to="/cadastrar" state={{ doador: dados }} ><FaEdit size={30} style={{color:'black'}} /></Link> </button>
        </div>
    )
    


  }

  function ButtonDoador ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();

    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{bwidth:'100%', backgroundColor:'#CBDCF7', borderTopRightRadius:20}}>
        <button style={{width:'100%', backgroundColor:'#CBDCF7', height:30, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row',  borderTopRightRadius:20}}   ><TesteFont style={{fontSize:'16px'}}>{dados.nome}</TesteFont></button>
        
        </div>
        :
        <div style={{width:'100%', backgroundColor:'#CBDCF7', borderTopRightRadius:20}}>
        <button style={{width:'100%', backgroundColor:'#CBDCF7', height:30, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row',  borderTopRightRadius:20}}   ><TesteFont style={{fontSize:'16px'}}>{dados.nome}</TesteFont></button>
        </div>
    )
    


  }

  function Menu() {
    return (
      <Dropdown style={{width:150, marginTop: matches ? 20 : 10, marginBottom:20}}>
        <Dropdown.Toggle style={{backgroundColor:'black', marginLeft:'5%', width:'40%', height:50}}  id="">
          <MdMenu />
        {/* <IoMenuOutline style={{}} /> */}
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{backgroundColor:'black'}}>
          <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/cadastrar">Cadastrar</Link></Dropdown.Item>
          <Dropdown.Divider color='white' />
          {/* <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/doadores">Doadores</Link></Dropdown.Item> */}
          <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doadores">Doações</Link></Dropdown.Item>
          <Dropdown.Divider color='white' />
          <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doacoes">Doadores</Link></Dropdown.Item>
          {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      
    );
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // A aba voltou a ficar visível
        // Atualize o estado ou execute alguma ação
        console.log('A aba está visível novamente');
        executa();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  function handleDoacao(dados){

    console.log(dados);

    const config = {
      method: 'post',
      // url local
      // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
      // url: `ApiDoador.php?action=insert_doador`,
      url: baseurl,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout:1000,
      data: {
        'id': null,
        'iddoador': dados.id,                            
        'action': 'insert_doacao'
      }
    };

    const req = axios(config).then((res) => { 
      console.log(res);
      toast.success('Doação Cadastrada com Sucesso!', {              
        // onClose: () =>  window.location.href = '/doadores'
      })
      // window.location.href = '/';
      // reset({
      //     id:state.doador.id,
      //     nome: "",
      //     telefone:"",
      //     dtnascimento:"",
      //     tiposanguineo:""
      //   })


     });

  }

  

  return (
    // <div className="App">
    matches ?  
      <header className="App-header">


<div style={{position:'fixed', minWidth:'70%', alignSelf:'center', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
          <div style={{display:'flex', marginTop:'1%', flexDirection:'row'}}>
        <div style={{width:'5%'}}>
        <Menu />
        </div>

        <div style={{display:'flex', width:'100%', justifyContent:"center", flexDirection:'row',}}>

        <img src={gota1} style={{height:'90%', width:'9%'}} alt="Logo" />

        <div style={{marginLeft:'10%'}}>
          <h2 style={{color:'blue', fontWeight:'bold', fontSize:'10vh'}}>Doe Vida</h2>
          <h2 style={{color:'blue', fontFamily:'cursive', textAlign:'center', fontSize:'5vh', letterSpacing:3, fontStyle:'italic', marginTop:-15, paddingTop:0}}>Macaé</h2>
          </div>
          </div>
          </div>

        
        
      {/* <div style={{
        // backgroundColor:'blue',
        position:'fixed',
        backgroundImage:`url(${topo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        
        top:0,
        height:'100px',
        width:'100%'
      }} class="header"> */}

{/* <Menu /> */}


<div style={{backgroundColor: '#5769BD', width:'100%', alignSelf:'flex-start', textAlign:'center'}}>
<h1 style={{}}>Doações</h1>


{/* <div >
       {matches && (<h1>Big Screen</h1>)}
       {!matches && (<h3>Small Screen</h3>)}
     </div> */}

<input
               style={{
                width: '75%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                // marginBottom:'3%'
               }}
               placeholder="Buscar doador"        
               // placeholderTextColor="#FFFFFF"
              //  value={search}
               onInput={(text) => searchFilter(text.target.value)}
            ></input>

<div style={{display:'flex', justifyContent:'space-between', alignSelf:'center', flexDirection:'row', width:'100%', alignItems:'stretch'}}>
<label>
        <input
          type="radio"
          value="A+"
          checked={valorSelecionado === 'A+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        A+
      </label>

      <label>
        <input
          type="radio"
          value="A-"
          checked={valorSelecionado === 'A-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        A-
      </label>

      <label>
        <input
          type="radio"
          value="B+"
          checked={valorSelecionado === 'B+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        B+
      </label>

      <label>
        <input
          type="radio"
          value="B-"
          checked={valorSelecionado === 'B-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        B-
      </label>

      <label>
        <input
          type="radio"
          value="AB+"
          checked={valorSelecionado === 'AB+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        AB+
      </label>

      <label>
        <input
          type="radio"
          value="AB-"
          checked={valorSelecionado === 'AB-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        AB-
      </label>

      <label>
        <input
          type="radio"
          value="O+"
          checked={valorSelecionado === 'O+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        O+
      </label>

      <label>
        <input
          type="radio"
          value="O-"
          checked={valorSelecionado === 'O-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        O-
      </label>

      </div>


            </div>

            
            </div>




  <div style={{width:'55%', marginTop:'25%', alignSelf:'center', display:'flex', marginLeft:'3%', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start'}}>
        {filteredData.map((rowData, rowIndex) => (
          
            <div style={{display:'flex', backgroundColor: 'white', marginBottom: '4%', width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20, flexDirection:'column'}}>
            <ButtonDoador dados={rowData} />
            <div style={{display:'flex', marginTop:5, marginBottom:5, justifyContent:'space-around', flexDirection:'row'}}>
            <ButtonEdit dados={rowData} />
            <ButtonDoe dados={rowData} />
            <ButtonAgradece dados={rowData} />
            <ButtonDelete dados={rowData} />
            <ButtonAdd dados={rowData} />
            </div>
            </div>
            // <div style={{backgroundColor: 'white', marginBottom: '1%', marginTop:'2%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
            // <button onClick={setTelefone(rowData.telefone)} style={{width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont>{rowData.nome}</TesteFont></button>
            // </div>

        ))

        }
</div>
       

<div>
        {/*Your others component*/}
      <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      type="error"
      
      />
      
    </div>

            {/* <div style={{width:'55%', marginTop:'30%', alignSelf:'center', display:'flex', marginLeft:'3%', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start'}}>
        {filteredData.map((rowData, rowIndex) => (
          
            
            <ButtonDoador dados={rowData} />
           

        ))

        }
</div> */}
     

      </header>
      : 


      <header className="App-header">

<div style={{position:'fixed', width:'100%', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
          <div style={{display:'flex', marginTop:'3%', marginBottom:'3%', flexDirection:'row'}}>
        <div style={{width:'25%'}}>
        <Menu />
        </div>

        <div style={{display:'flex', width:'100%', justifyContent:"center", flexDirection:'row',}}>

        <img src={gota1} width={60} height={70} alt="Logo" />

        <div style={{marginLeft:'10%'}}>
          <h2 style={{color:'blue', fontWeight:'bold', fontSize:'6vh'}}>Doe Vida</h2>
          <h2 style={{color:'blue', fontFamily:'cursive', textAlign:'center', fontSize:'5vh', letterSpacing:3, fontStyle:'italic', marginTop:-15, paddingTop:0}}>Macaé</h2>
          </div>
          </div>
          </div>

        
        
      {/* <div style={{
        // backgroundColor:'blue',
        position:'fixed',
        backgroundImage:`url(${topo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        
        top:0,
        height:'100px',
        width:'100%'
      }} class="header"> */}

{/* <Menu /> */}


<div style={{backgroundColor: '#5769BD', width:'100%', alignSelf:'flex-start', textAlign:'center'}}>
<h1 style={{}}>Doações</h1>


{/* <div >
       {matches && (<h1>Big Screen</h1>)}
       {!matches && (<h3>Small Screen</h3>)}
     </div> */}

<input
               style={{
                width: '75%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                marginBottom:'3%'
               }}
               placeholder="Buscar doador"        
               // placeholderTextColor="#FFFFFF"
              //  value={search}
               onInput={(text) => searchFilter(text.target.value)}
            ></input>

<div style={{display:'flex', justifyContent:'space-around', flexDirection:'row', width:'100%', alignItems:'stretch'}}>
<label>
        <input
          type="radio"
          value="A+"
          checked={valorSelecionado === 'A+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        A+
      </label>

      <label>
        <input
          type="radio"
          value="A-"
          checked={valorSelecionado === 'A-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        A-
      </label>

      <label>
        <input
          type="radio"
          value="B+"
          checked={valorSelecionado === 'B+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        B+
      </label>

      <label>
        <input
          type="radio"
          value="B-"
          checked={valorSelecionado === 'B-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        B-
      </label>

      <label>
        <input
          type="radio"
          value="AB+"
          checked={valorSelecionado === 'AB+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        AB+
      </label>

      <label>
        <input
          type="radio"
          value="AB-"
          checked={valorSelecionado === 'AB-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        AB-
      </label>

      <label>
        <input
          type="radio"
          value="O+"
          checked={valorSelecionado === 'O+'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        O+
      </label>

      <label>
        <input
          type="radio"
          value="O-"
          checked={valorSelecionado === 'O-'}
          onChange={(text) => searchFilter(null, text.target.value)}
        />
        O-
      </label>

      </div>
            </div>
            </div>
        
     

            <div style={{width:'95%', marginTop:'70%', display:'flex', marginLeft:'3%', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start'}}>
        {filteredData.map((rowData, rowIndex) => (
          
            <div style={{display:'flex', backgroundColor: 'white', marginBottom: '4%', width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20, flexDirection:'column'}}>
            <ButtonDoador dados={rowData} />
            <div style={{display:'flex', marginTop:5, marginBottom:5, justifyContent:'space-around', flexDirection:'row'}}>
            <ButtonEdit dados={rowData} />
            <ButtonDoe dados={rowData} />
            <ButtonAgradece dados={rowData} />
            <ButtonDelete dados={rowData} />
            <ButtonAdd dados={rowData} />
            </div>
            </div>
            // <div style={{backgroundColor: 'white', marginBottom: '1%', marginTop:'2%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
            // <button onClick={setTelefone(rowData.telefone)} style={{width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont>{rowData.nome}</TesteFont></button>
            // </div>

        ))

        }
</div>
       

<div>
        {/*Your others component*/}
      <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      type="error"
      
      />
      
    </div>

      </header>
    // </div>
  );
}

export default App;
