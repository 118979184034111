import logo from './logo.svg';
import './App.css';
import Rotas from './Rotas';

function App() {

  const numbers = [1, 2, 3, 4, 5];
const ListItems = () => {numbers.map((number) =>
  {return <li key={number.toString()}>
    {number}
  </li>}
)};

  return (
    <Rotas />
  );
}

export default App;
