import React from 'react';
import { useRoutes, Routes, Route, HashRouter as Router } from 'react-router-dom';
import Home from './screens/Cadastrar/Cadastrar';
import About from './screens/Doadores/Doadores';
import Doacoes from './screens/Doacoes/Doacoes';

const App = () => {
    return(
//    <Router>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cadastrar" element={<Home />} />
        <Route path="/doadores" element={<About /> } />
        <Route path="/doacoes" element={<Doacoes /> } />
  {/* let routes = useRoutes([
    { path: '/cadastrar', element: <Home /> },
    { path: '/doadores', element: <About /> },
  ]); */}
  </Routes>
//   </Router>
    )
//   return routes;
};

export default App;