import React, { useState, useEffect } from 'react';
import { TextInputMask, datetimeMask, celPhoneMask } from 'react-masked-text';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { MdMenu } from "react-icons/md";

import { Link, useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import gota1 from '../../assets/gota1.png'

import topo from '../../assets/topo_new.png';
import topo_desktop from '../../assets/topo_desktop_new.png';

export default function Cadastrar() {

  let { state } = useLocation();

  // console.log(state.doador.nome);

  // url teste local
  // const baseurl = `https://doevidamacae.appsesites.com.br/ApiDoador.php`;

  //url em produção
  const baseurl = `ApiDoador.php`;



  const [sucesso, setSucesso] = useState(false);
  // const [nome, setNome] = useState('dfsdfsdfs');
  // const { register, handleSubmit } = useForm();
  // const { handleSubmit, control } = useForm();
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const { setValue, register, handleSubmit, reset, control, formState,
    formState: { isSubmitSuccessful }, } = useForm({
      defaultValues: { nome: "", telefone: "", dtnascimento: "", tiposanguineo: "" }
      // resolver: yupResolver(schema),
      // validationSchema: fieldsValidationSchema
    });


  function handleSalvar(data) {
    // setIsLoading(true);       

    console.log('Salvar doador');
    console.log(data);
    setSucesso(true);
    
    if(state){

      const config = {
        method: 'post',
        // url local
        // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
        // url: `ApiDoador.php?action=insert_doador`,
        url: baseurl,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout:1000,
        data: {
          'id': state.doador.id,
          'nome': data.nome,
          'telefone': data.telefone,
          'dtnascimento': data.dtnascimento, 
          'tiposanguineo': data.tiposanguineo,                 
          'action': 'insert_doador'
        }
      };

      const req = axios(config).then((res) => { 
        toast.success('Doação Cadastrada com Sucesso!', {              
          onClose: () =>  window.location.href = '/doacoes'
        })
        // window.location.href = '/';
        // reset({
        //     id:state.doador.id,
        //     nome: "",
        //     telefone:"",
        //     dtnascimento:"",
        //     tiposanguineo:""
        //   })
  
  
       });

    } else {

    // if (item?.id > 0) {
    //     console.log(item);
    //     const config = {
    //         method: 'post',
    //         url: `https://appsesites.com.br/ApiPedidos.php?action=insert_clientes`,
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         },
    //         timeout:1000,
    //         data: {
    //           'id': item.id,
    //           'nome': data.empresa,
    //           'endereco': data.endereco,
    //           'cidade': data.cidade,
    //           'estado': data.estado,
    //           'cnpj': data.cnpj,
    //           'inscestadual': data.inscestadual,
    //           'action': 'insert_clientes'
    //         }
    //       };

    //       const req = axios(config).then((res) => { 


    //         navigation.navigate('Clientes', {
    //             modalVisible: false
    //         })
    //         // navigation.navigate('Clientes', {
    //         //     itemId: route.params.itemId.id
    //         // })
    //        });
    // }

    // if(typeof route !== "undefined" && !item?.id){
    //     console.log("Não é undefined");
    //     if(route.params.itemId.id){
    //         const config = {
    //             method: 'post',
    //             url: `https://appsesites.com.br/ApiPedidos.php?action=insert_clientes`,
    //             headers: {
    //               'Content-Type': 'multipart/form-data'
    //             },
    //             timeout:1000,
    //             data: {
    //               'id': route.params.itemId.id,
    //               'nome': data.empresa,
    //               'endereco': data.endereco,
    //               'cidade': data.cidade,
    //               'estado': data.estado,
    //               'cnpj': data.cnpj,
    //               'inscestadual': data.inscestadual,
    //               'action': 'insert_clientes'
    //             }
    //           };

    //           const req = axios(config).then((res) => { 
    //             navigation.navigate('Clientes', {
    //                 itemId: route.params.itemId.id
    //             })
    //            });
    //         // ApiCliente.inserir_cliente(route.params.itemId.id, data.empresa, data.endereco, data.cidade, data.estado, data.cnpj, data.inscestadual, data.pagamento);

    //         // navigation.navigate('Clientes', {
    //         //     itemId: route.params.itemId.id
    //         // })
    //     }
    // }
    // else if(!item?.id){
    // console.log("É undefined");
    console.log("salvar");
    // toast.success('Doação Cadastrada com Sucesso!', {              
    //   onClose: () =>  window.location.href = '/'
    // })
    // reset({
    //             nome: "",
    //             telefone:"",
    //             dtnascimento:""
    //           })

    
    const config = {
        method: 'post',
        // url local
        // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
        // url: `ApiDoador.php?action=insert_doador`,
        url: baseurl,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout:1000,
        data: {
          'id': null,
          'nome': data.nome,
          'telefone': data.telefone,
          'dtnascimento': data.dtnascimento, 
          'tiposanguineo': data.tiposanguineo,                 
          'action': 'insert_doador'
        }
      };

    const req = axios(config).then((res) => { 
      toast.success('Doação Cadastrada com Sucesso!', {              
        onClose: () =>  window.location.href = '/'
      })
      // window.location.href = '/';
      reset({
          nome: "",
          telefone:"",
          dtnascimento:"",
          tiposanguineo:""
        })


     });

    }


    // ApiCliente.inserir_cliente(null, data.empresa, data.endereco, data.cidade, data.estado, data.cnpj, data.inscestadual, data.pagamento);

    // reset({
    //   empresa: "",
    //   endereco:""
    // })

    // navigation.navigate('Clientes', {
    //     modalVisible: false
    // })

    // }

    // navigation.navigate('Produtos');
    // setModalVisible(!modalVisible);
    //     useEffect(() => {
    //         const focusHandler = navigation.addListener('focus', () => {
    //             Alert.alert('Refreshed');
    //         });
    //         return focusHandler;
    //     }, [navigation]);

    // navigation.navigate('Clientes');  


  }

  function Menu() {
    return (
      <Dropdown style={{ width: 150, marginTop: 20 }}>
        <Dropdown.Toggle style={{ backgroundColor: 'black', marginLeft: '5%', width: '40%', height: 50 }} id="">
          <MdMenu />
          {/* <IoMenuOutline style={{}} /> */}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ backgroundColor: 'black' }}>
          <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/cadastrar">Cadastrar</Link></Dropdown.Item>
          <Dropdown.Divider color='white' />
          {/* <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doadores">Doadores</Link></Dropdown.Item> */}
          <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doadores">Doações</Link></Dropdown.Item>
          <Dropdown.Divider color='white' />
          <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doacoes">Doadores</Link></Dropdown.Item>
          {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>

    );
  }

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    // Atualize os valores do formulário após a montagem do componente, se necessário
    if(state){
    setValue('nome', state.doador.nome);
    setValue('telefone', state.doador.telefone);
    setValue('dtnascimento', state.doador.dtnascimento);
    setValue('tiposanguineo', state.doador.tiposanguineo);
    }
    
  }, [state]);

  useEffect(() => {
    

    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  // setNome('');

  // useEffect(() => {

  //         reset({
  //             nome: "",
  //             telefone:"",
  //             dtnascimento:""
  //           })

  //   }, [sucesso])
  const handleClick = () => toast.success('Mensagem de sucesso', {
    onOpen: () => window.alert('Called when I open'),
    onClose: () => window.alert('Called when I close')
  })
  return (

    matches ?

      <header className="App-header">

<div style={{position:'fixed', minWidth:'70%', alignSelf:'center', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
          <div style={{display:'flex', marginTop:'1%', flexDirection:'row'}}>
        <div style={{width:'5%'}}>
        <Menu />
        </div>

        <div style={{display:'flex', width:'100%', justifyContent:"center", flexDirection:'row',}}>

        <img src={gota1} style={{height:'90%', width:'9%'}} alt="Logo" />

        <div style={{marginLeft:'10%'}}>
          <h2 style={{color:'blue', fontWeight:'bold', fontSize:'10vh'}}>Doe Vida</h2>
          <h2 style={{color:'blue', fontFamily:'cursive', textAlign:'center', fontSize:'5vh', letterSpacing:3, fontStyle:'italic', marginTop:-15, paddingTop:0}}>Macaé</h2>
          </div>
          </div>
          </div>

        
            </div>

        <h1 style={{ marginTop: '12%', alignSelf:'center' }}>Cadastro de Doadores</h1>



        <form style={{alignSelf:'center'}} onSubmit={handleSubmit(data => handleSalvar(data))}>
          <Controller
            control={control}
            name="nome"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label><h5>Nome</h5></label>
                <input

                  style={{
                    backgroundColor: '#FFF',
                    width: '100%',

                    marginBottom: 15,
                    color: '#222',
                    fontSize: 14,
                    borderRadius: 7,
                    padding: 10
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}

                />
              </div>
            )}
            defaultValue=""
          />

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Controller
              control={control}
              name="telefone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label><h5>Telefone</h5></label>
                  <TextInputMask
                    mask={celPhoneMask()}
                    style={{
                      backgroundColor: '#FFF',
                      width: '80%',

                      marginBottom: 15,
                      color: '#222',
                      fontSize: 14,
                      borderRadius: 7,
                      padding: 10
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                </div>
              )}
              defaultValue=""
            />

            <Controller
              control={control}
              name="dtnascimento"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label><h5>Data de Nascimento</h5></label>
                  <TextInputMask
                    mask={datetimeMask()}
                    style={{
                      backgroundColor: '#FFF',
                      width: '100%',

                      marginBottom: 15,
                      color: '#222',
                      fontSize: 14,
                      borderRadius: 7,
                      padding: 10
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                </div>
              )}
              defaultValue=""
            />
          </div>

          <Controller
            control={control}
            name="tiposanguineo"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Tipo Sanguíneo</label>
                <select style={{
                  backgroundColor: '#FFF',
                  width: '30%',

                  marginBottom: 15,
                  color: '#222',
                  fontSize: 22,
                  borderRadius: 7,
                  padding: 5
                }} onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  value={value}>
                  <option value="">...</option>  
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>


                </select>


              </div>
            )}
            defaultValue=""
          />


<div style={{display:'flex', flexDirection:'row', marginTop:'6%', alignItems:'center', justifyContent:'center'}}>
          <input style={{
            backgroundColor: '#59BFFF',
            width: '100%',
            height: 45,
            marginBottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 7,
            alignSelf: 'center',
            width: '45%'
          }} type="submit" />
          </div>
        </form>
        

      </header>

      :

      <header className="App-header">
        <div style={{position:'fixed', width:'100%', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
        <div style={{display:'flex', marginTop:'3%', marginBottom:'3%', flexDirection:'row'}}>
        
        <div style={{width:'25%'}}>
        <Menu />
        </div>

        <div style={{display:'flex', width:'100%', justifyContent:"center", flexDirection:'row',}}>

        <img src={gota1} width={60} height={70} alt="Logo" />

        <div style={{marginLeft:'10%'}}>
          <h2 style={{color:'blue', fontWeight:'bold', fontSize:'6vh'}}>Doe Vida</h2>
          <h2 style={{color:'blue', fontFamily:'cursive', textAlign:'center', fontSize:'5vh', letterSpacing:3, fontStyle:'italic', marginTop:-15, paddingTop:0}}>Macaé</h2>
          </div>
          </div>
          </div>
          </div>
        {/* <div style={{
          // backgroundColor:'blue',
          position: 'fixed',
          backgroundImage: `url(${topo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          top: 0,
          height: '100px',
          width: '100%'
        }} class="header">

          <Menu />

        </div> */}

        <h1 style={{ marginTop: '40%', alignSelf:'center' }}>Cadastro de Doadores</h1>

        <form style={{marginLeft:'10%'}} onSubmit={handleSubmit(data => handleSalvar(data))}>
          <Controller
            control={control}
            name="nome"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div>
                <label>Nome</label>
                <input

                  style={{
                    backgroundColor: '#FFF',
                    width: '90%',

                    marginBottom: 15,
                    color: '#222',
                    fontSize: 22,
                    borderRadius: 7,
                    padding: 5
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  value={value}

                />
              </div>
            )}
            defaultValue=''
          />

          <Controller
            control={control}
            name="telefone"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Telefone</label>
                <TextInputMask
                  mask={celPhoneMask()}
                  style={{
                    backgroundColor: '#FFF',
                    width: '70%',

                    marginBottom: 15,
                    color: '#222',
                    fontSize: 22,
                    borderRadius: 7,
                    padding: 5
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  value={value}
                />
              </div>
            )}
            defaultValue=""
          />

          <Controller
            control={control}
            name="dtnascimento"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Data de Nascimento</label>
                <TextInputMask
                  mask={datetimeMask()}
                  style={{
                    backgroundColor: '#FFF',
                    width: '70%',

                    marginBottom: 15,
                    color: '#222',
                    fontSize: 22,
                    borderRadius: 7,
                    padding: 5
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  value={value}
                />
              </div>
            )}
            defaultValue=""
          />

          <Controller
            control={control}
            name="tiposanguineo"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Tipo Sanguíneo</label>
                <select style={{
                  backgroundColor: '#FFF',
                  width: '30%',

                  marginBottom: 15,
                  color: '#222',
                  fontSize: 22,
                  borderRadius: 7,
                  padding: 5
                }} onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  value={value}>
                  <option value="">...</option>  
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>


                </select>


              </div>
            )}
            defaultValue=""
          />



<div style={{display:'flex', flexDirection:'row', marginTop:'6%', alignItems:'center', justifyContent:'center'}}>
          <input style={{
            backgroundColor: '#59BFFF',
            width: '100%',
            height: 45,
            marginBottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 7,
            alignSelf: 'center',
            width: '45%'
          }} type="submit" />
</div>

        </form>
        <div>
          {/*Your others component*/}
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            type="success"

          />

        </div>

      </header>

  );
}

