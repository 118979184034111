import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Rotas from './Rotas';

import { Route, BrowserRouter, Routes, useRoutes, HashRouter as Router } from "react-router-dom";

import Doadores from '../src/screens/Doadores/Doadores'
import Cadastrar from './screens/Cadastrar/Cadastrar';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter>
    {/* <Router> */}
  <React.StrictMode>
    
    <Rotas />    
    {/* <Cadastrar />
    <Doadores /> */}
    {/* <Doadores /> */}
  </React.StrictMode>
  {/*  </Router> */}
   </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
